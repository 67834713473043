import styles from "./RegisterInvite.module.scss";

import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";

import { useSearchParams } from 'react-router-dom';

import { JoinWaitListAPI } from "../../../api";

import CheckInboxModal from "../../modals/CheckInboxModal/CheckInboxModal";



import Loader from "react-spinners/BeatLoader";


const JoinSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email")
    .required("Email address is required"),
});

export const RegisterInvite = () => {

  const [searchParams] = useSearchParams();

  const [showCheckInboxModal, setShowCheckInboxModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  
  const [error, setError] = useState("");

  function emailChange() {
    setError("");
  }

  const utmSource = searchParams.get('utm_source') || '';
  const utmMedium = searchParams.get('utm_medium') || '';
  const utmCampaign = searchParams.get('utm_campaign') || '';

  return (
    <div className={styles.container}>
      <p className={styles.description}>
        Register your interest to receive your invite
      </p>

      <Formik
        validationSchema={JoinSchema}
        initialValues={{ email: "" }}
        onSubmit={(values) => {
          
          setFormSubmitted(true);
          setError("");
          
          const data = {
            email: values.email,
            artist: process.env.REACT_APP_ARTIST_ID,
          };

          if (utmSource && utmMedium && utmCampaign) {
            const utm = {};
            utm.utm_source = utmSource
            utm.utm_medium = utmMedium
            utm.utm_campaign = utmCampaign
            data.utm = utm
          }

          JoinWaitListAPI(data)
            .then(() => {
              values.email = ''
              setShowCheckInboxModal(true);
              setFormSubmitted(false);
              window.gtag('event', 'waitlist_sign_up', {});
            })
            .catch(({ response }) => {
              if (
                response.data &&
                (response.data.error === "Error: Email already exists" ||
                  response.data.error === "Email already exists" ||
                  response.data.error === "User Already Exists" ||
                  response.data.error === "Already In Waitlist" ||
                  response.data.error === "Already registered intrest")
              ) {
                setError("Looks like that email is already in use.");
              } else {
                setError("Something went wrong. Please try again.");
              }
              setFormSubmitted(false);
            });
        }}
      >
        <Form className={styles.input_container}>
        <div style={{'display': 'flex', 'width': 'fit-content', 'flexDirection': 'column'}}>
          <div style={{'display': 'flex', 'flexDirection': 'row', 'gap': '15px'}}>
            <Field
              name="email"
              type="text"
              placeholder="enter your email address"
              onKeyUp={emailChange}
            />
            <button type="submit" className={styles.btn} disabled={formSubmitted}>
              {formSubmitted ? (
                <>
                  <Loader color="white" />
                </>
              ) : (
                <>Register</>
              )}
            </button>
          </div>
          <div style={{'display': 'flex', 'flexDirection': 'column'}}>
            {error ? (
              <>
                <p className={styles.error} style={{'flexDirection': 'column', 'marginTop': '15px'}}>{error}</p>
              </>
            ) : (
              <>
                <ErrorMessage
                  component="p"
                  className={styles.error}
                  style={{'flexDirection': 'column', 'marginTop': '15px'}}
                  name="email"
                />
              </>
            )}
            </div>
          </div>
        </Form>
      </Formik>

      <CheckInboxModal
        show={showCheckInboxModal}
        onClose={() => setShowCheckInboxModal(false)}
      />

    </div>
  );
};

