import React from "react";

// libs
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// components
import { Footer } from "../Footer/Footer";
import { routes } from "../../../utils/routes";

// assets
import styles from "./Layout.module.scss";
import songbits from "../../../images/icons/songbits-without-text.svg";

export const Layout = ({
  noLogo,
  background,
  title,
  artistName,
  songName,
  children,
  className,
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={
            songName +
            " - " +
            artistName +
            " a on SongBits. A platform where you can buy shared ownership in songs directly from your favorite artists and earn royalties together with them for life."
          }
        />
        <meta content={title} property="og:title"></meta>
        <meta
          content={
            songName +
            " - " +
            artistName +
            " a on SongBits. A platform where you can buy shared ownership in songs directly from your favorite artists and earn royalties together with them for life."
          }
          property="og:description"
        ></meta>
        <meta
          content={
            songName +
            " - " +
            artistName +
            " a on SongBits. A platform where you can buy shared ownership in songs directly from your favorite artists and earn royalties together with them for life."
          }
          property="twitter:description"
        ></meta>
      </Helmet>
      <section className={`${styles.layout} ${className}`}>
        <div className={styles.background_container}>
          <img
            className={`${styles.background} background`}
            src={background}
            alt="background"
          />
          <div className={styles.shadow_container}></div>
        </div>
        <div className="container">
          <div className={styles.content_container}>
            {!noLogo && (
              <Link to={routes.home} className={styles.logo}>
                <img src={songbits} alt="" />
              </Link>
            )}
            {children}
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};
