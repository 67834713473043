import { useState, useEffect } from "react";
import ReactHowler from "react-howler";

import styles from "./ImagePlayer.module.scss";
import COVER from "../../../images/phoebe_cover.png";
import play from "../../../images/icons/playWithoutCircle.svg";
import pause from "../../../images/icons/pause.svg";

export const ImagePlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (isPlaying) {
      window.gtag('event', 'waitlist_play_preview', {});
    }

  }, [isPlaying])

  return (
    <div className={styles.player_container}>
      <img className={styles.img} src={COVER} alt="song cover" />
      <div className={styles.player}>
        <img
          className={styles.play}
          src={isPlaying ? pause : play}
          alt=""
          height={isPlaying ? 60 : 60}
          width={isPlaying ? 60 : 60}
          onClick={() => setIsPlaying((prev) => !prev)}
        />
        <ReactHowler
          src="https://sb-stg-api.s3.us-west-2.amazonaws.com/sb-stg-api/47a701afe9be99857a0ca5dc7d7e964b6c2c80d6f7c3574e20acf38eebb101ba9b7e0be920afdd3fb0fa2beb225b56c55b37ffde711ddc6662c1ba8237c99c18.mp3"
          playing={isPlaying}
          onEnd={() => setIsPlaying(false)}
          loop={false}
        />
      </div>
    </div>
  );
};
