import React, { useState } from "react";

// components
import { VideoModal } from "../../modals";
import { Countdown, ImagePlayer, Layout } from "../../common";
import { useWindowSize } from "../../../utils/useWindowSize";
import CheckInboxModal from "../../modals/CheckInboxModal/CheckInboxModal";
import SignInModal from "../../modals/SignInModal/SignInModal";

// assets
import styles from "./BeforeReleaseDate.module.scss";
import playBtn from "../../../images/icons/play.svg";
import background from "../../../images/phoebe.png";
import user from "../../../images/icons/user.svg";
import mp3 from "../../../images/icons/mp3.svg";
import mp3_outlined from "../../../images/icons/mp3-transparent.svg";
import profile_outlined from "../../../images/icons/profile-transparent.svg";
import money from "../../../images/icons/money.svg";
import money_outlined from "../../../images/icons/money-transparent.svg";
import ticket from "../../../images/icons/ticket.svg";
import stream from "../../../images/icons/video.svg";
import signed from "../../../images/icons/signed.svg";
import history from "../../../images/icons/content.svg";
import hear from "../../../images/icons/hear.svg";
import spotify from "../../../images/icons/spotify_filled.svg";
import instagram from "../../../images/icons/instagram.svg";
import youtube from "../../../images/icons/youtube.svg";
import tiktok from "../../../images/icons/tiktok_filled.svg";

import { RegisterInvite } from "../../common/RegisterInivite/RegisterInvite";

export const BeforeReleaseDate = () => {
  const { width } = useWindowSize();

  const [showCheckInboxModal, setShowCheckInboxModal] = useState(false);

  const benefits = [];

  benefits.push({
    title:
      "Hear it first! Buyers get to hear the song up to 14 days before its available to the public to stream*",
    icon: hear,
  });

  if (process.env.REACT_APP_PERK_ROYALTY) {
    benefits.push({
      title:
        "A share of the streaming revenues from ‘" +
        process.env.REACT_APP_SONG_NAME +
        "’ forever!",
      icon: money,
    });
  }

  benefits.push({
    title:
      "A personalized video message from Ashley & Bodine for the first 100 buyers",
    icon: stream,
  });

  if (process.env.REACT_APP_PERK_NFT_IMAGE) {
    benefits.push({
      title: "A unique numbered exclusive image just for you",
      icon: user,
    });
  }

  benefits.push({
    title:
      "Exclusive access to Ashley’s unreleased music and historical tracks",
    icon: history,
  });

  if (process.env.REACT_APP_PERK_TICKET) {
    benefits.push({ title: process.env.REACT_APP_PERK_TICKET, icon: ticket });
  }

  if (process.env.REACT_APP_PERK_DOWNLOAD) {
    benefits.push({ title: "An MP3 download of the song to keep", icon: mp3 });
  }

  benefits.push({
    title: "Video Hangout With Ash & Bodine for the top 20 buyers.",
    icon: stream,
  });

  benefits.push({
    title: "Signed Ashley Wallbridge exclusive album for the first 20 buyers",
    icon: signed,
  });

  const [showVideo, setShowVideo] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);


  const artist_name = process.env.REACT_APP_ARTIST_NAME;
  const song_name = process.env.REACT_APP_SONG_NAME;

  const title =
    process.env.REACT_APP_SONG_NAME +
    " - " +
    process.env.REACT_APP_ARTIST_NAME +
    " on SongBits";
  const video_url = process.env.REACT_APP_VIDEO_URL;

  const str_date = process.env.REACT_APP_RELEASE_AT;


function lastSunday(month, year) {
  const d = new Date();
  const lastDayOfMonth = new Date(Date.UTC(year || d.getFullYear(), month+1, 0));
  const day = lastDayOfMonth.getDay();
  return new Date(Date.UTC(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() - day));
}

function isBST(date) {
  const d = date || new Date();
  const starts = lastSunday(2, d.getFullYear());
  starts.setHours(1);
  const ends = lastSunday(9, d.getFullYear());
  starts.setHours(1);
  return d.getTime() >= starts.getTime() && d.getTime() < ends.getTime();
}


function checkLive(date_string) {

  const str_date = date_string

  const year = parseInt(str_date.substring(0, 4));
  const month = parseInt(str_date.substring(5, 7)) - 1;
  const day = parseInt(str_date.substring(8, 10));
  
  let hour = parseInt(str_date.substring(11, 13));
  //const hour = parseInt(str_date.substring(11, 13));
  const minute = parseInt(str_date.substring(14, 16));
  const second = parseInt(str_date.substring(17, 19));

  const date_ = new Date(Date.UTC(year, month, day, hour, minute, second))

  if (isBST(date_)) {
    hour -= 1
  }

  const date = new Date(Date.UTC(year, month, day, hour, minute, second)).getTime()

  const now = Date.now()

  return {
    date_epoch: date,
    now_epoch: now
  }

}

const is_live = checkLive(str_date);

const date_check = is_live.now_epoch > is_live.date_epoch


  const CountDown = () => (
    <div className={styles.countdown}>
      {video_url && (
        <div className={styles.play} onClick={() => setShowVideo(true)}>
          <img src={playBtn} alt="" />
        </div>
      )}
      <div className={styles.timer}>
        <Countdown time={is_live.date_epoch} />
      </div>
    </div>
  );


  return (
    <Layout
      className={styles.beforeRelease}
      title={title}
      artistName={process.env.REACT_APP_ARTIST_NAME}
      songName={process.env.REACT_APP_SONG_NAME}
      background={background}
      noLogo
    >
      {showVideo && <VideoModal setShowVideo={setShowVideo} src={video_url} />}
      <div className={styles.wrapper} id="coverContainer">
        <section className={styles.left_column}>
          <div className={styles.player_container}>
            <div className={styles.player_container_wrapper}>
              <ImagePlayer />
              <p className={styles.title_counter}>COUNTDOWN TO SALE</p>
            </div>
            <CountDown />
          </div>
          {!date_check ? <>
          <RegisterInvite />
          </> : <>
          <button
            className={styles.btnPost}
            onClick={() => {
              //setShowSignInModal(true)
            }}
          >
            Sale Ended
          </button>
          </>}
        </section>

        <section className={styles.right_column}>
          <div className={styles.title_container}>
            <h1 className={styles.title}>{song_name}</h1>
            <h2 className={styles.artist_name}>{artist_name}</h2>
            <h3 className={styles.sub_text}>ownership release</h3>

            <div className={styles.player_container} hidden={width > 768}>
              <div className={styles.player_container_wrapper}>
                <ImagePlayer />
                <p className={styles.title_counter}>COUNTDOWN TO SALE</p>
              </div>
              <CountDown />
            </div>
          </div>

          <div className={styles.content_container}>
            <p className={styles.content_text}>
              Grab your chance to share in the success of Phoebe Willo's latest
              song 'Lost in the Music exclusively for fans {!date_check ? 'releasing on' : 'released on'}
            </p>
            <p className={styles.yellow_text}>28th July 2023 5pm (BST/GMT+1)</p>

            {!date_check ? <>
            <p className={styles.content_text}>
              Register your interest now to receive your exclusive invite to buy
              a bit of the song and receive a share of the song's streaming
              royalties for life plus the exclusive VIP benefits listed below.
            </p>
            </> : <></>}
          </div>

          {width < 768 && (
            <div className={styles.register_wrapper}>
            {!date_check ? <>
            <RegisterInvite />
            </> : <>
            <button
              className={styles.btnPost}
              onClick={() => {
                //setShowSignInModal(true)
              }}
            >
              Sale Ended
            </button>
            </>}
            </div>
          )}

          <div className={styles.content_container}>
            <p className={styles.content_title}>vip benefits included</p>
            <div className={styles.benefits_container}>
              <div className={styles.benefit}>
                <img src={history} alt="" />
                <p>
                  Signed limited edition vinyl record (first 75 buyers over $75)
                </p>
              </div>
              <div className={styles.benefit}>
                <img src={mp3_outlined} alt="" />
                <p>An MP3 download of the song to keep</p>
              </div>
              <div className={styles.benefit}>
                <img src={profile_outlined} alt="" />
                <p>A unique numbered exclusive image just for you</p>
              </div>
              <div className={styles.benefit}>
                <img src={money_outlined} alt="" />
                <p>
                  A share of the streaming revenues of ‘lost in the music’ forever
                </p>
              </div>
            </div>
          </div>

          <div className={styles.content_container}>
            <p className={styles.content_title}>about the artist</p>
            <p className={styles.content_text}>
              Phoebe Willo, 20, launched her singer-songwriter career in
              September 2022 and immediately caught the attention of Spotify's
              New Music Friday with two of her early singles. Achieving 500K
              streams within 3 months, and now close to breaking her first
              million streams, Phoebe is still "blown away" by the numerous
              reviews her music has received.
            </p>
            <p className={styles.content_text}>
              A graduate of WaterBear,
              Phoebe writes with keys, guitar and ukulele, sharing her personal
              stories with breath taking honesty.
            </p>
            <p className={styles.content_text}>
              Looking ahead, Phoebe will be embarking on more live shows, and
              releasing more music for her ever-growing fanbase.
            </p>
            <div className={styles.icons_container}>
              <a
                href="https://open.spotify.com/artist/4TWNdeVFnZhFlWBwwd3Dgu?si=&nd=1"
                target="_blank"
                rel="noreferrer"
              >
                <img src={spotify} alt="spotify icon" width={35} height={35} />
              </a>
              <a
                href="https://www.instagram.com/phoebewillo_/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={instagram}
                  alt="instagram icon"
                  width={35}
                  height={35}
                />
              </a>
              <a
                href="https://www.youtube.com/@PhoebeWillo"
                target="_blank"
                rel="noreferrer"
              >
                <img src={youtube} alt="youtube icon" width={35} height={35} />
              </a>
              <a
                href="https://www.tiktok.com/@phoebewillo"
                target="_blank"
                rel="noreferrer"
              >
                <img src={tiktok} alt="youtube icon" width={35} height={35} />
              </a>
            </div>
          </div>

          <div className={styles.register_wrapper}>
          {!date_check ? <>
          <RegisterInvite />
          </> : <>
          <button
            className={styles.btnPost}
            onClick={() => {
              //setShowSignInModal(true)
            }}
          >
            Sale Ended
          </button>
          </>}
          </div>

          <div className={styles.content_container}>
            <p className={styles.content_title}>what's being said</p>
            <div className={styles.citation_container}>
              <p className={`${styles.content_text} ${styles.margin_0}`}>
                “It’s hard to find authentic and original home-grown music in
                this day and age. That was until we found Phoebe Willo.”
              </p>
              <p className={styles.yellow_text}>The Skadoosh</p>
            </div>
            <div className={styles.citation_container}>
              <p className={`${styles.content_text} ${styles.margin_0}`}>
                “Phoebe Willo flexes her indie-pop muscles on her latest
                single.”
              </p>
              <p className={styles.yellow_text}>Buffablog</p>
            </div>
            <div className={styles.citation_container}>
              <p className={`${styles.content_text} ${styles.margin_0}`}>
                “Embracing impressive control over her diverse vocal range, she
                flaunts her upper register …it is intoxicating…”
              </p>
              <p className={styles.yellow_text}>The Wild is Calling</p>
            </div>
            <div className={styles.citation_container}>
              <p className={`${styles.content_text} ${styles.margin_0}`}>
                “A vision within music unlike any other.”
              </p>
              <p className={styles.yellow_text}>Affinity Ascension</p>
            </div>
            <div className={styles.citation_container}>
              <p className={`${styles.content_text} ${styles.margin_0}`}>
                “…undeniable 11/10. Willo’s musicality deserves recognition” -
                Glasse Factory “Immersive writing and personable appeal.”
              </p>
              <p className={styles.yellow_text}>Clout</p>
            </div>
          </div>
          <div className={styles.register_wrapper}>
          {!date_check ? <>
          <RegisterInvite />
          </> : <>
          <button
            className={styles.btnPost}
            onClick={() => {
              //setShowSignInModal(true)
            }}
          >
            Sale Ended
          </button>
          </>}
          </div>
        </section>
      </div>
      <CheckInboxModal
        show={showCheckInboxModal}
        onClose={() => setShowCheckInboxModal(false)}
      />
      <SignInModal
          show={showSignInModal}
          onClose={() => setShowSignInModal(false)}
          onCreateAccount={() => {
            //window.location.href = process.env.REACT_APP_SITE_URL + '/create-account?artist=' + process.env.REACT_APP_ARTIST_ID
          }}
          onAlreadyHaveAccount={() => {
            //window.location.href = process.env.REACT_APP_SITE_URL + process.env.REACT_APP_SONG_URL
          }}
        />

    </Layout>
  );
};
